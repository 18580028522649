import React, { useState, useEffect } from "react";
import { marked } from "marked";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./styles.scss";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const MdPage = () => {
  /*
        A page that renders any other .md file separate from the blog
        page: parameter that defines which .md file to load
    */

  const [post, setPost] = useState({});
  const [notFound, setNotFound] = useState(false);
  const { filename } = useParams();

  useEffect(() => {
    setNotFound(false);
    axios
      .get(`/md_pages/pages/${filename}.md`)
      .then((response) => {
        const content = response.data;
        const sections = {
          image:
            content.match(/<!-- image -->([\s\S]*?)<!-- \/image -->/)?.[1] ||
            "",
          headlineSplit:
            content.match(
              /<!-- headline-split -->([\s\S]*?)<!-- \/headline-split -->/,
            )?.[1] || "",
          numberedSectionHeadline:
            content.match(
              /<!-- numbered-section-headline -->([\s\S]*?)<!-- \/numbered-section-headline -->/,
            )?.[1] || "",
          numberedSections:
            content.match(
              /<!-- numbered-sections -->([\s\S]*?)<!-- \/numbered-sections -->/,
            )?.[1] || "",
          repeatedSections:
            content.match(
              /<!-- repeated-section -->([\s\S]*?)<!-- \/repeated-section -->/g,
            ) || [],
          other:
            content.match(/<!-- other -->([\s\S]*?)<!-- \/other -->/)?.[1] ||
            "",
        };

        sections.numberedSections = sections.numberedSections
          .split("\n")
          .filter((section) => section.trim() !== "");

        setPost({
          image: marked(sections.image),
          headlineSplit: marked(sections.headlineSplit),
          numberedSectionHeadline: marked(sections.numberedSectionHeadline),
          numberedSections: sections.numberedSections.map((section) =>
            marked(section),
          ),
          repeatedSections: sections.repeatedSections.map((section) =>
            marked(section),
          ),
          other: marked(sections.other),
        });
      })
      .catch((error) => {
        setNotFound(true);
        console.error("Error loading the blog post:", error);
      });
  }, [filename]);

  return notFound ? (
    <NotFoundPage />
  ) : (
    post && (
      <div className="blog-page">
        <div
          className="image"
          dangerouslySetInnerHTML={{ __html: post.image }}
        />
        <div className="main-part">
          <div className="upper-part">
            {post.headlineSplit && (
              <div
                className="headline-split"
                dangerouslySetInnerHTML={{ __html: post.headlineSplit }}
              ></div>
            )}
          </div>
          <div className="numbered-sections">
            <div
              className="headline"
              dangerouslySetInnerHTML={{ __html: post.numberedSectionHeadline }}
            ></div>
            <div className="sections">
              {post.numberedSections &&
                post.numberedSections.map((section, index) => (
                  <div key={index}>
                    <div className="number">{`0${index + 1}`}</div>
                    <div className="numbered-section">
                      <div dangerouslySetInnerHTML={{ __html: section }} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {post.repeatedSections &&
            post.repeatedSections.map((section, index) => (
              <div
                key={index}
                className="repeated-section"
                dangerouslySetInnerHTML={{ __html: section }}
              />
            ))}
          <div
            className="other"
            dangerouslySetInnerHTML={{ __html: post.other }}
          />
        </div>
      </div>
    )
  );
};

export default MdPage;
