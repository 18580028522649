import React, { useState, useEffect } from "react";
import TooltipComponent from "../TooltipComponent/TooltipComponent.jsx";
import { useDispatch, useSelector } from "react-redux";
import { createPlaylist, fetchPlaylists } from "../../actions/playlist.js";
import CreatePlaylistModal from "../CreatePlaylistModal/CreatePlaylistModal.jsx";
import { checkIsDuplicatePlaylist } from "../../hooks/usePlaylist.ts";
import {
  notifyError,
  notifySuccess,
  showSignup,
} from "../../actions/global.action.js";
import DropdownMultiple from "../DropdownMultiple/DropdownMultiple.jsx";
import ButtonComponent from "../ButtonComponent/ButtonComponent.jsx";
import "./styles.scss";
import { playlistService } from "../../services/playlist.service.ts";
import addToPlaylistBlack from "../../assets/add-to-playlist-black.svg";
import addToPlaylistBlackSmaller from "../../assets/add-to-playlist-black-smaller.svg";
import addToPlaylistWhite from "../../assets/add-to-playlist-white.svg";

//not used icons anymore
// import newAddToPlaylistBlack from "../../assets/new-add-to-playlist-black.svg";
// import newAddToPlaylistWhite from "../../assets/new-add-to-playlist-white.svg";

const PlaylistDropdown = ({
  addFunction = "video",
  isDashboard = false,
  itemId,
}) => {
  /*
    Available playlists dropdown for video cards.
    Allows to create a new playlist and add the video to existing playlists or delete it from them.
  */

  const [showDropdown, setShowDropdown] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const dispatch = useDispatch();
  const playlists = useSelector((state) => state.playlist.playlists);
  const playlistLoading = useSelector((state) => state.playlist.loading);
  const [chosenOptions, setChosenOptions] = useState([]);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (showDropdown && playlists.length === 0) {
      dispatch(fetchPlaylists());
    }
  }, [showDropdown]);

  useEffect(() => {
    setChosenOptions(
      playlists.filter((playlist) =>
        playlist.children.some((child) => child.object_id === itemId),
      ),
    );
  }, [playlists]);

  const handleAddToPlaylist = async (playlistId) => {
    setShowDropdown(false);
    if (addFunction == "video") {
      return playlistService
        .addVideoToPlaylist(itemId, playlistId)
        .then(() => {
          dispatch(notifySuccess("Playlists updated successfully!"));
        })
        .catch(() => {
          dispatch(notifyError(`You can't add private videos to playlist`));
        });
    } else if (addFunction == "card") {
      return playlistService
        .addCardToPlaylist(itemId, playlistId)
        .then(() => {
          dispatch(notifySuccess("Playlists updated successfully!"));
        })
        .catch(() => {
          dispatch(notifyError(`You can't add private cards to playlist`));
        });
    } else {
      return playlistService
        .addPlaylistToPlaylist(itemId, playlistId)
        .catch((error) => {
          console.error("Error adding Playlist to playlist:", error);
        });
    }
  };

  const handleRemove = async (itemId) => {
    return playlistService.deleteFromPlaylist(itemId).catch((error) => {
      console.error("Error removing playlist item:", error.message);
    });
  };

  const handleCreatePlaylist = (title) => {
    const playlistTitle = title.trim();
    if (playlistTitle.length > 100) {
      dispatch(
        notifyError("Playlist name should be no longer than 100 characters!"),
      );
      return;
    }

    const isDuplicated =
      playlists.length > 0 &&
      checkIsDuplicatePlaylist(playlistTitle, playlists);
    if (isDuplicated) {
      dispatch(notifyError("Please choose a different name!"));
      return;
    }

    setShowCreateForm(false);
    setShowDropdown(false);

    dispatch(createPlaylist(playlistTitle, "private", "", [], false, null))
      .then(() => {
        dispatch(notifySuccess("Playlist created successfully!"));
        dispatch(fetchPlaylists());
      })
      .catch((error) => {
        console.error("Error creating playlist:", error);
      });
  };

  const handleSave = async (newOptions) => {
    const chosenIds = new Set(chosenOptions.map((option) => option.object_id));
    const newIds = new Set(newOptions.map((option) => option.object_id));

    const toAdd = newOptions.filter(
      (option) => !chosenIds.has(option.object_id),
    );
    const toRemove = chosenOptions.filter(
      (option) => !newIds.has(option.object_id),
    );

    if (toAdd.length === 0 && toRemove.length === 0) {
      setShowDropdown(false);
      return;
    }

    setShowDropdown(false);

    const addPromises = toAdd.map((option) =>
      handleAddToPlaylist(option.object_id),
    );

    const removePromises = toRemove.map((option) => {
      const idToDelete = option.children?.find(
        (child) => child.object_id === itemId,
      ).playlist_item_id;
      return handleRemove(idToDelete);
    });

    try {
      await Promise.all([...addPromises, ...removePromises]);
      dispatch(fetchPlaylists());
      dispatch(notifySuccess("Playlists updated successfully!"));
    } catch (error) {
      console.error("Error updating playlists:", error);
    }
  };

  useEffect(() => {
    if (showDropdown && !isAuthenticated) {
      dispatch(showSignup(true));
    }
  }, [showDropdown]);

  return (
    <div className="playlist-dropdown-container">
      <div className="label-option">
        <button
          className={`sidebar-btn ${showDropdown && !isDashboard && "selected"} ${isDashboard ? "dashboard-btn" : ""}`}
          onClick={() => setShowDropdown(!showDropdown)}
          data-cy="playlist-dropdown-button"
        >
          {!isDashboard ? (
            <img
              className="control-icon-img"
              src={showDropdown ? addToPlaylistWhite : addToPlaylistBlack}
              alt="playlist"
            />
          ) : (
            <TooltipComponent
              tipText={"Add to playlist"}
              placement={"bottom"}
              icon={addToPlaylistBlackSmaller}
            />
          )}
        </button>
        {!isDashboard ? <p className="sidebar-text">Add</p> : null}
      </div>
      {showDropdown && isAuthenticated && (
        <div
          className={`dropdown-multiple-component ${isDashboard ? "" : "feed-dropdown"}`}
        >
          <DropdownMultiple
            loading={playlistLoading}
            headerText={"Choose a playlist"}
            options={playlists}
            chosenOptions={chosenOptions}
            onClick={(playlist) => handleAddToPlaylist(playlist.object_id)}
            setShowDropdown={setShowDropdown}
            handleSave={handleSave}
            isMyContent={isDashboard}
            before={
              showCreateForm ? (
                <CreatePlaylistModal
                  handleCancel={() => setShowCreateForm(false)}
                  handleCreate={handleCreatePlaylist}
                />
              ) : (
                <div style={{ width: "100%", marginLeft: "-10px" }}>
                  <ButtonComponent
                    text="Create new playlist"
                    level="ghost"
                    handleClick={() => setShowCreateForm(true)}
                  />
                </div>
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default PlaylistDropdown;
