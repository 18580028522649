import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef } from "react";
import VideoItem from "../VideoItem/VideoItem.jsx";
import SearchBarComponent from "../SearchBarComponent/SearchBarComponent.jsx";
import PlaylistItem from "../PlaylistItem/PlaylistItem.jsx";
import Pagination from "@mui/material/Pagination";
import CrossSign from "../../assets/cross-sign.svg";
import useOutsideClick from "../../hooks/useOutsideClick.js";
import "./styles.scss";
import { videoService } from "../../services/video.service.ts";
import VideoPreview from "../VideoPreview/VideoPreview.jsx";
import Spinner from "../Spinner/Spinner.jsx";
import Popup from "../../containers/Popup/Popup.jsx";
import { hideModal } from "../../actions/global.action.js";

const PAGE_SIZE = 20;

const AddVideoToPlaylistPopOut = () => {
  /*
    Pop out window to add a video/nested playlist to a 'parent' playlist.
    Accessible via Dashboard > Playlists and /playlists.
    Opens a window when clicked on + in any playlist or 'Add items' in Playlist preview.

    Three tabs available:
      - Videos
      - Cards
      - Playlists
  */

  // Accessing video data from the Redux store
  const dispatch = useDispatch();
  const currentPlaylist = useSelector(
    (state) => state.playlist.currentPlaylist,
  );
  const [queryResults, setQueryResults] = useState();
  const [queryTerm, setQueryTerm] = useState("");
  const [category, setCategory] = useState("videos");
  const [activeTab, setActiveTab] = useState("videos");
  const [pagination, setPagination] = useState({
    videos: { currentPage: 1, pagesCount: 1 },
    cards: { currentPage: 1, pagesCount: 1 },
    playlists: { currentPage: 1, pagesCount: 1 },
  });
  const [videoPreview, setVideoPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const addRef = useRef(null);
  useOutsideClick(addRef, () => setClosePopup(true));
  const [closePopup, setClosePopup] = useState(false);

  const handleClose = () => {
    dispatch(hideModal());
  };

  const handlePaginationChange = (ev, page) => {
    setPagination({
      ...pagination,
      [category]: {
        ...pagination[category],
        currentPage: page,
      },
    });
  };

  const handleQuery = (page_change = false) => {
    setLoading(true);
    const currentPage = page_change ? pagination[category].currentPage : 1; // If page_change is false, set currentPage to 1
    videoService
      .search(queryTerm, category, currentPage - 1) // 1-indexed for frontend to 0-indexed for backend
      .then((response) => {
        setQueryResults(response);
        setPagination((prev) => ({
          ...prev,
          [category]: {
            ...prev[category],
            currentPage: page_change ? prev[category].currentPage : 1, // Reset to 1 if queryTerm or category changes
            pagesCount: Math.ceil(response?.statistics?.[category] / PAGE_SIZE),
          },
        }));
      })
      .catch((error) => {
        console.error("Search error:", error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleQuery();
  }, [queryTerm, category]);

  useEffect(() => {
    handleQuery(true);
  }, [pagination[category].currentPage]);

  return (
    <Popup
      className="video-add-wrapper"
      closePopup={closePopup}
      closeFunc={handleClose}
      withContainer={true}
      dataCy="add-to-playlist-modal"
    >
      <div className="video-add-form popup" ref={addRef}>
        {videoPreview !== null ? (
          <VideoPreview
            videoPreview={videoPreview}
            setVideoPreview={setVideoPreview}
            popout={true}
            handleClose={() => setClosePopup(true)}
          />
        ) : (
          <>
            <div className="video-add-title">
              Add Items
              <div
                className="playlists-search"
                data-cy="add-to-playlist-modal-search-bar"
              >
                <SearchBarComponent
                  placeholder={"Search the item name"}
                  handleChange={(e) => {
                    setQueryTerm(e.target.value?.toLowerCase());
                  }}
                  setIsFocused={() => {}}
                  handleClose={() => {
                    setQueryTerm("");
                  }}
                />
              </div>
              <button
                className="video-add-cross-sign"
                onClick={() => setClosePopup(true)}
                data-cy="add-to-playlist-modal-close-button"
              >
                <img src={CrossSign} alt="X" />
              </button>
            </div>
            <div className="add-content">
              <div className="video-add-tabs">
                <button
                  className={`search-tab-button ${activeTab === "videos" ? "active" : ""}`}
                  onClick={() => {
                    if (!loading) {
                      setActiveTab("videos");
                      setCategory("videos");
                    }
                  }}
                  data-cy="video-tab"
                >
                  Videos
                </button>
                <button
                  className={`search-tab-button ${activeTab === "cards" ? "active" : ""}`}
                  onClick={() => {
                    if (!loading) {
                      setActiveTab("cards");
                      setCategory("cards");
                    }
                  }}
                  data-cy="card-tab"
                >
                  Cards
                </button>
                <button
                  className={`search-tab-button ${activeTab === "playlists" ? "active" : ""}`}
                  onClick={() => {
                    if (!loading) {
                      setActiveTab("playlists");
                      setCategory("playlists");
                    }
                  }}
                  data-cy="playlist-tab"
                >
                  Playlists
                </button>
              </div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                    width: "100%",
                  }}
                >
                  <Spinner />
                </div>
              ) : (
                queryResults && (
                  <>
                    {queryResults?.statistics?.[activeTab] === 0 && (
                      <div className="no-results-text">No results found</div>
                    )}
                    <div className="video-list">
                      <div>
                        {activeTab === "videos" && (
                          <div>
                            {queryResults?.videos?.map((video) => (
                              <VideoItem
                                key={video.id}
                                video={video}
                                playlist={currentPlaylist}
                                preview={setVideoPreview}
                                isVideo={true}
                              />
                            ))}
                          </div>
                        )}
                        {activeTab === "cards" && (
                          <div>
                            {queryResults?.cards?.map((card) => (
                              <VideoItem
                                key={card.id}
                                video={card}
                                playlist={currentPlaylist}
                                preview={setVideoPreview}
                                isVideo={false}
                              />
                            ))}
                          </div>
                        )}
                        {activeTab === "playlists" && (
                          <div>
                            <div>
                              {queryResults?.playlists
                                ?.filter(
                                  (pl) => pl.id !== currentPlaylist?.object_id,
                                )
                                .map((playlist) => (
                                  <>
                                    {playlist?.card_count +
                                      playlist?.video_count >
                                    0 ? (
                                      <PlaylistItem
                                        key={playlist.id}
                                        item={playlist}
                                        playlist={currentPlaylist}
                                        setClosePopup={setClosePopup}
                                      />
                                    ) : null}
                                  </>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {pagination[activeTab].pagesCount > 1 && (
                      <div className="pagination">
                        <Pagination
                          count={pagination[activeTab].pagesCount}
                          color="primary"
                          onChange={handlePaginationChange}
                          page={pagination[activeTab].currentPage}
                        />
                      </div>
                    )}
                  </>
                )
              )}
            </div>
          </>
        )}
      </div>
    </Popup>
  );
};

export default AddVideoToPlaylistPopOut;
