import { ApiService } from "./api.service.ts";

class PlaylistService {
  async getPlaylist(
    playlistId: string,
    preview: boolean = false,
  ): Promise<any> {
    return await ApiService.get(`/playlist/${playlistId}/?preview=${preview}`);
  }

  async getPlaylistToEdit(playlistId: string): Promise<any> {
    return await ApiService.get(`/playlist/get-edit-playlist/${playlistId}/`);
  }

  async getPublicPlaylists(): Promise<any> {
    return await ApiService.get("/playlists/public/");
  }

  async getUserPlaylists(userId: string): Promise<any> {
    return await ApiService.get(`/user/${userId}/playlists/`);
  }

  async generatePlaylist(query: string): Promise<any> {
    return await ApiService.post(`/search/ai/playlist`, { query });
  }

  async createPlaylist(
    title: string,
    visibility: string,
    description: string,
    videos: string[],
    ai_generated: boolean = false,
    thumbnail: File | null = null,
  ): Promise<any> {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("visibility", visibility);
    formData.append("description", description);
    formData.append("ai_generated", ai_generated.toString());

    videos.forEach((videoId, index) => {
      formData.append(`videos[${index}]`, videoId);
    });

    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    return await ApiService.post("/playlists/create/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async addVideoToPlaylist(videoId: string, playlistId: string): Promise<any> {
    return await ApiService.post(`/playlists/${playlistId}/add_video/`, {
      video_id: videoId,
    });
  }

  async addCardToPlaylist(cardId: string, playlistId: string): Promise<any> {
    return await ApiService.post(`/playlists/${playlistId}/add_card/`, {
      cardId: cardId,
    });
  }

  async addPlaylistToPlaylist(
    childPlaylistId: string,
    playlistId: string,
  ): Promise<any> {
    return await ApiService.post(`/playlists/${playlistId}/add_playlist/`, {
      child_playlist_id: childPlaylistId,
    });
  }

  async editPlaylist(playlistId: string, formData: FormData): Promise<any> {
    return await ApiService.post(
      `/playlist/edit-playlist/${playlistId}/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }

  async updatePlaylistStructure(
    data: any,
    currentItemId?: string,
  ): Promise<any> {
    return await ApiService.post("/playlists/update-playlist-structure/", {
      data,
      current_item_id: currentItemId,
    });
  }

  async deleteFromPlaylist(objectId: string): Promise<any> {
    return await ApiService.delete(`/playlist/item/${objectId}/remove/`);
  }

  async deleteItemFromPlaylist(
    playlistId: string,
    itemId: string,
  ): Promise<any> {
    return await ApiService.delete(
      `/playlist/${playlistId}/item/${itemId}/remove/`,
    );
  }

  async deletePlaylist(playlistId: string): Promise<any> {
    return await ApiService.delete(`/playlists/${playlistId}/delete/`);
  }

  async duplicatePlaylist(itemId: string, parentId: string): Promise<any> {
    return await ApiService.post(
      `/playlist/duplicate-and-add/${itemId}/${parentId}/`,
    );
  }

  async changePlaylistVisibility(
    playlistId: string,
    visibility: string,
  ): Promise<any> {
    return await ApiService.post(`/playlist/change-visibility/${playlistId}/`, {
      visibility,
    });
  }
}

export const playlistService = new PlaylistService();
