import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { setData } from "../actions/data.js";
import { videoService } from "../services/video.service.ts";
import { cardService } from "../services/card.service.ts";
import { playlistService } from "../services/playlist.service.ts";

import FeedContainer from "../pages/FeedContainer/FeedContainer.jsx";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage.jsx";
import { showSignup, showSubscription } from "../actions/global.action.js";
import { maxVideos, videoBatch } from "../utils/constants.js";

const FeedWrapper = ({ type, isMyTab, video }) => {
  /*
    FeedWrapper is a container that fetches data from the backend and passes it to the FeedContainer component.
    It determines the data that should be shown on FeedContainer based on the type of the page.
    It only cares about the logic and data of the FeedContainer, but not styling.

    Props:
    - type: string. Possible value: "feed", "playlist", "playlist-tab", "video", "video-tab", "video-preview", "card"
    - isMyTab: boolean. If true, the page is the user's profile page.
     */

  // General Hooks
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Dispatch Store variables
  const data = useSelector((state) => state.data.data);
  const playlistUuidTab = useSelector(
    (state) => state.playlist.currentPlaylist,
  );
  const currentPlaylist = useSelector((state) => state.playlist);
  const videoUuid = currentPlaylist?.object_id;

  // Feed Wrapper state variables
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0); // feed card where user left off last time
  const [cardRefs, setCardRefs] = useState(); // refs for all feed cards
  const [totalNumOfCards, setTotalNumOfCards] = useState();
  const [playlistTitle, setPlaylistTitle] = useState("");

  // Edge cases
  const [isEmpty, setIsEmpty] = useState(false);
  const [isPrivatePage, setIsPrivatePage] = useState(false);
  const [notFound, setNotFound] = useState(null);

  // Auth & Loading
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const showSignUpForm = !isAuthenticated;
  const [loading, setLoading] = useState(true);

  //Video pagination variables (Feed)
  // const [page, setPage] = useState(2);
  // const [hasMore, setHasMore] = useState(true);
  // const [loadingForward, setLoadingForward] = useState(false);
  // const [loadingBackward, setLoadingBackward] = useState(false);
  // const [deletedContent, setDeletedContent] = useState(0);

  const fetchFeedPaginated = async () => {
    try {
      await videoService.sortVideos();
      const videos = await videoService.getVideosPaginated();
      dispatch(setData([...videos]));
    } catch (error) {
      console.error("Error fetching videos for feed:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchVideo = async (id) => {
    try {
      setNotFound(null);
      const response = await videoService.getVideo(id);
      let recommendedVideos = [];
      try {
        recommendedVideos = await videoService.getRecommended(id);
      } catch (error) {
        console.error("Error fetching recommended videos:", error);
      }

      dispatch(setData([response, ...recommendedVideos]));
    } catch (err) {
      setNotFound("video");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchCard = async (id) => {
    try {
      setNotFound(null);
      const response = await cardService.getCard(id);
      const updatedResponse = {
        ...response,
        card_type: response.card_type,
        image: response.image_url,
        imageBack: response.image_url_back,
      };
      let recommendedVideos = [];
      try {
        recommendedVideos = await videoService.getRecommended(id);
      } catch (error) {
        console.error("Error fetching recommended videos:", error);
      }
      dispatch(setData([updatedResponse, ...recommendedVideos]));
    } catch (err) {
      setNotFound("card");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPlaylist = async (id) => {
    try {
      const current = searchParams.get("current");
      setNotFound(null);
      const response = await playlistService.getPlaylist(id);
      dispatch(setData([...response.children]));
      // TODO: uncomment this if we want to show the additional top and bottom cards for the playlist page
      // dispatch(setData([response, ...response.children, response]));
      if (response.children.length === 0) {
        setIsEmpty(true);
      }
      if (current) {
        const index = response.children.findIndex(
          (item) => item.id === current,
        );
        if (index !== -1) setCurrentVideoIndex(index + 1);
      }
      setPlaylistTitle(response.title);
    } catch (err) {
      setNotFound("playlist");
      setIsPrivatePage(true);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadFeed = async () => {
      setLoading(true);
      const response = await videoService.canWatchMoreVideos();
      if (!response.can_watch) {
        if (isAuthenticated) {
          navigate("/");
          dispatch(showSubscription());
        } else {
          navigate("/");
          dispatch(showSignup());
        }
      } else if (type === "feed") {
        fetchFeedPaginated();
      } else if (
        type === "playlist" &&
        location.pathname.includes("/playlist")
      ) {
        fetchPlaylist(location.pathname.split("/")[2]);
      } else if (type === "playlist-tab") {
        fetchPlaylist(playlistUuidTab?.object_id);
      } else if (type === "video") {
        fetchVideo(location.pathname.replace(/\/$/, "").split("/").pop());
      } else if (type === "video-tab") {
        fetchVideo(videoUuid);
      } else if (type === "video-preview") {
        dispatch(setData([video]));
        setLoading(false);
      } else if (type === "card") {
        fetchCard(location.pathname.replace(/\/$/, "").split("/").pop());
      }
    };
    loadFeed();
  }, [playlistUuidTab, type, location]);

  // To update cardRefs & number of cards on data initialization
  useEffect(() => {
    if (data && data.length > 0) {
      setCardRefs({
        current: new Array(data?.length)
          .fill(null)
          .map(() => React.createRef()),
      });
      setTotalNumOfCards(data?.length);
    }
  }, [data]);

  //// The code below was about "infinite" loading of feed and is outdated.
  //// When we decide to re-enable `/feed`, we need to update this code
  //// to fit with the rest of the FeedContainer visibility logic.

  // const fetchVideosForward = async () => {
  //   if (loadingForward) return;
  //   setLoadingForward(true);
  //   try {
  //     const response = await videoService.getVideosPaginated(page);
  //     const newVideoNumber = response.length;
  //     if (newVideoNumber < videoBatch) {
  //       setHasMore(false);
  //       return;
  //     }
  //     setPage((prev) => prev + 1);
  //     const newRefs = Array(newVideoNumber).fill(React.createRef());
  //     let oldData = [...data];
  //     let oldRefs = [...cardRefs.current];
  //     if (data.length + newVideoNumber > maxVideos) {
  //       oldData = oldData.slice(videoBatch);
  //       oldRefs = oldRefs.slice(videoBatch);
  //       setDeletedContent((prev) => prev + 1);
  //     }
  //     setCardRefs({ current: [...oldRefs, ...newRefs] });
  //     setTotalNumOfCards(oldData.length + newVideoNumber);
  //     dispatch(setData([...oldData, ...response]));
  //   } catch (error) {
  //     console.error("Error fetching videos", error);
  //   } finally {
  //     setLoadingForward(false);
  //   }
  // };

  // const fetchVideosBack = async () => {
  //   if (loadingBackward) return;
  //   setLoadingBackward(true);
  //   try {
  //     const response = await videoService.getVideosPaginated(deletedContent);
  //     const newVideoNumber = response.length;
  //     const newRefs = Array(newVideoNumber).fill(React.createRef());
  //     let oldData = [...data];
  //     let oldRefs = [...cardRefs.current];
  //     if (data.length + newVideoNumber > maxVideos) {
  //       oldData = oldData.slice(0, maxVideos - videoBatch);
  //       oldRefs = oldRefs.slice(0, maxVideos - videoBatch);
  //       setPage((prev) => prev - 1);
  //       setHasMore(true);
  //     }
  //     setDeletedContent((prev) => prev - 1);
  //     dispatch(setData([...response, ...oldData]));
  //     setCardRefs({ current: [...newRefs, ...oldRefs] });
  //     setTotalNumOfCards(oldData.length + newVideoNumber);
  //   } catch (error) {
  //     console.error("Error fetching videos", error);
  //   } finally {
  //     setLoadingBackward(false);
  //   }
  // };

  // const handleIntersection = useCallback(
  //   (entries) => {
  //     const [entry] = entries;
  //     if (entry.isIntersecting) {
  //       fetchVideosForward();
  //     }
  //   },
  //   [fetchVideosForward],
  // );

  // const handleIntersectionBack = useCallback(
  //   (entries) => {
  //     const [entry] = entries;
  //     if (entry.isIntersecting) {
  //       fetchVideosBack();
  //     }
  //   },
  //   [fetchVideosBack],
  // );

  // // Rendering of videos forward -- Feed
  // useEffect(() => {
  //   if (
  //     type === "feed" &&
  //     !isAuthCard &&
  //     hasMore &&
  //     cardRefs?.current &&
  //     cardRefs.current[totalNumOfCards - 2]?.current
  //   ) {
  //     const observer = new IntersectionObserver(handleIntersection, {
  //       root: null,
  //       rootMargin: "0px",
  //       threshold: 0.1,
  //     });
  //     observer.observe(cardRefs.current[totalNumOfCards - 2].current);
  //     return () => {
  //       if (cardRefs.current[totalNumOfCards - 2]?.current) {
  //         observer.unobserve(cardRefs.current[totalNumOfCards - 2].current);
  //       }
  //     };
  //   }
  // }, [totalNumOfCards, cardRefs, deletedContent, handleIntersection]);

  // // Rendering of videos backwards (if any) -- Feed
  // useEffect(() => {
  //   if (
  //     type === "feed" &&
  //     !isAuthCard &&
  //     deletedContent > 0 &&
  //     cardRefs?.current &&
  //     cardRefs.current[2]?.current
  //   ) {
  //     const observer = new IntersectionObserver(handleIntersectionBack, {
  //       root: null,
  //       rootMargin: "0px",
  //       threshold: 0.1,
  //     });
  //     observer.observe(cardRefs.current[2].current);
  //     return () => {
  //       if (cardRefs.current[2]?.current) {
  //         observer.unobserve(cardRefs.current[2]?.current);
  //       }
  //     };
  //   }
  // }, [cardRefs, deletedContent, handleIntersectionBack]);

  if (loading) return <div className="spinner-container-feed"></div>;

  return notFound ? (
    <NotFoundPage pageType={notFound} />
  ) : (
    <FeedContainer
      data={data}
      type={type}
      isMyTab={isMyTab}
      isPrivatePage={isPrivatePage}
      isEmpty={isEmpty}
      showSignUpForm={showSignUpForm}
      currentVideoIndex={currentVideoIndex}
      playlistTitle={playlistTitle}
      cardRefs={cardRefs}
      totalNumOfCards={totalNumOfCards}
    />
  );
};

export default FeedWrapper;
