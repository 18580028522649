import clsx from "clsx";
import React, { forwardRef } from "react";
import type { TreeItemComponentProps } from "./types";
import "./SimpleTreeItemWrapper.css";
import sixDots from "../../assets/six-dots.svg";
import arrowDown from "../../assets/chevron-down.svg";
import arrowRight from "../../assets/chevron-right-2020.svg";

interface ExtendedTreeItemProps extends TreeItemComponentProps<{}> {
  isSelected?: boolean;
  isVideo?: boolean;
  isCreating?: boolean;
  currentPlaylistName?: string;
  onUserClick?: () => void;
}

export const SimpleTreeItemWrapper = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ExtendedTreeItemProps>
>((props, ref) => {
  const {
    clone,
    depth,
    disableSelection,
    disableInteraction,
    disableSorting,
    ghost,
    handleProps,
    indentationWidth,
    indicator,
    collapsed,
    onCollapse,
    onRemove,
    item,
    wrapperRef,
    style,
    hideCollapseButton,
    childCount,
    manualDrag,
    showDragHandle,
    disableCollapseOnItemClick,
    isLast,
    parent,
    className,
    contentClassName,
    isOver,
    isOverParent,
    onUserClick,
    isSelected,
    isVideo,
    isCreating,
    currentPlaylistName,
    ...rest
  } = props;

  return (
    <li
      ref={wrapperRef}
      {...rest}
      className={clsx(
        "dnd-sortable-tree_simple_wrapper",
        clone && "dnd-sortable-tree_simple_clone",
        ghost && "dnd-sortable-tree_simple_ghost",
        disableSelection && "dnd-sortable-tree_simple_disable-selection",
        disableInteraction && "dnd-sortable-tree_simple_disable-interaction",
        className,
      )}
      style={{
        ...style,
        paddingLeft: clone ? indentationWidth : indentationWidth * depth,
      }}
    >
      <div
        className={clsx(
          isCreating && "dnd-sortable-tree_simple_tree-item-creating",
          currentPlaylistName === "" &&
            "dnd-sortable-tree_simple_tree-item-creating-empty",
          isSelected
            ? "dnd-sortable-tree_simple_tree-item-chosen"
            : "dnd-sortable-tree_simple_tree-item",
          contentClassName,
        )}
        ref={ref}
        {...(manualDrag ? undefined : handleProps)}
        onClick={disableCollapseOnItemClick ? onUserClick : onCollapse}
      >
        {!disableSorting && showDragHandle !== false && !isVideo && (
          <div className={"dnd-sortable-tree_simple_handle"} {...handleProps}>
            <img src={sixDots} />
          </div>
        )}
        {!manualDrag && !hideCollapseButton && !!onCollapse && !!childCount && (
          <button
            onClick={(e) => {
              if (!disableCollapseOnItemClick) {
                return;
              }
              e.preventDefault();
              onCollapse?.();
            }}
            className={clsx(
              isSelected
                ? "dnd-sortable-tree_folder_simple-item-collapse_button-collapsed"
                : "dnd-sortable-tree_simple_tree-item-collapse_button",
            )}
            data-cy="playlist-item-collapse-button"
          >
            <img src={collapsed ? arrowRight : arrowDown} />
          </button>
        )}
        {isCreating && currentPlaylistName === "" ? (
          <span className="untitled">Untitled</span>
        ) : (
          ""
        )}
        {props.children}
      </div>
    </li>
  );
}) as <T>(
  p: React.PropsWithChildren<
    ExtendedTreeItemProps & React.RefAttributes<HTMLDivElement>
  >,
) => React.ReactElement;
