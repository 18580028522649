import React, { useEffect, useRef, useState, useCallback } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import Popup from "../../containers/Popup/Popup.jsx";
import CrossSign from "../../assets/cross-sign.svg";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent.jsx";
import CardComponent from "../../components/CardComponent/CardComponent.jsx";
import { cardService } from "../../services/card.service.ts";
import "./styles.scss";
import { notifyError, notifySuccess } from "../../actions/global.action";
import { useDispatch } from "react-redux";
import AlertModal from "../AlertModal/AlertModal.jsx";
import AssCard from "../../components/Cards/AssCard";
import { cardTypeDict } from "../../utils/constants.js";
import { useNavigate } from "react-router-dom";
import { fetchCards } from "../../actions/card";
import { isRichTextEmpty } from "../../utils/editor";
import ColorPickerComponent from "../../components/ColorPickerComponent/ColorPickerComponent.jsx";

const UploadAssCard = ({ handleClose }) => {
  const uploadRef = useRef(null);
  const uploadBodyLeftRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isSmallerMobile, setIsSmallerMobile] = useState(false);
  const [isOneColumn, setIsOneColumn] = useState(false);
  useOutsideClick(uploadRef, () => setShowDangerModal(true));
  const navigate = useNavigate();
  const [cardInfo, setCardInfo] = useState({
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    image: null,
    imageBack: null,
    card_type: "Informational",
    visibility: "public",
    explanation: "",
    description: "",
    descriptionBack: "",
    title: "",
    color: "#58A5F8",
    code: null,
    language: "Python",
    hint: null,
    correctAnswer: 0,
    code_string: "",
    is_image_background: false,
    is_back_image_background: false,
  });
  const [showImageComponent, setShowImageComponent] = useState(true);
  const [showCodeComponent, setShowCodeComponent] = useState(false);
  const fileInputRef = useRef(null);
  const fileBackInputRef = useRef(null);
  const dispatch = useDispatch();
  const [showDangerModal, setShowDangerModal] = useState(false);
  const [canBeSaved, setCanBeSaved] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const handleResize = useCallback(() => {
    if (uploadBodyLeftRef.current) {
      const { width } = uploadBodyLeftRef.current.getBoundingClientRect();
      setIsMobile(width < 650);
      setIsSmallerMobile(width < 450);
      setIsOneColumn(
        ((window.innerHeight - 330) * 9) / 16 + 80 >
          0.75 * window.innerWidth - 300,
      );
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(handleResize);
    });

    if (uploadBodyLeftRef.current) {
      resizeObserver.observe(uploadBodyLeftRef.current);
    }

    handleResize();

    return () => {
      resizeObserver.disconnect();
    };
  }, [handleResize]);

  const handleDrop = (e, isFront = true) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleImageFile(files[0], isFront);
    }
  };

  const handleChange = (e, isFront = true) => {
    const files = e.target.files;
    if (files.length > 0) {
      handleImageFile(files[0], isFront);
    }
  };

  const handleImageFile = (file, isFront = true) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      if (isFront) {
        setCardInfo((prevCardInfo) => ({
          ...prevCardInfo,
          image: base64String,
        }));
      } else {
        setCardInfo((prevCardInfo) => ({
          ...prevCardInfo,
          imageBack: base64String,
        }));
      }
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (showDangerModal) {
      if (
        cardInfo.title.trim() === "" &&
        cardInfo.answer1.trim() === "" &&
        cardInfo.answer2.trim() === ""
      ) {
        handleClose();
        setShowDangerModal(false);
      }
    }
  }, [showDangerModal]);

  const handleSave = async () => {
    if (
      cardInfo.title.trim() === "" ||
      (cardInfo.answer1.trim() === "" &&
        cardInfo.card_type === "Multiple Choice") ||
      (cardInfo.answer2.trim() === "" &&
        cardInfo.card_type === "Multiple Choice") ||
      (cardInfo.description === null &&
        cardInfo.image === null &&
        cardInfo.card_type === "Flashcard") ||
      (cardInfo.descriptionBack === null &&
        cardInfo.imageBack === null &&
        cardInfo.card_type === "Flashcard")
    ) {
      dispatch(notifyError("Please fill in all required fields!"));
      return;
    }

    setIsSaveDisabled(true);

    const answers = [
      cardInfo.answer1,
      cardInfo.answer2,
      cardInfo.answer3,
      cardInfo.answer4,
    ];
    const filteredAnswers = answers.filter(
      (answer) => answer !== null && answer.trim() !== "",
    );

    if (filteredAnswers.length !== new Set(filteredAnswers).size) {
      dispatch(notifyError("Please ensure all answers are unique!"));
      setIsSaveDisabled(false);
      return;
    }

    const cardData = {
      title: cardInfo.title,
      description: JSON.stringify(cardInfo.description),
      descriptionBack: JSON.stringify(cardInfo.descriptionBack),
      hint: cardInfo.hint,
      correct_answer: cardInfo.correctAnswer,
      answers: [
        cardInfo.answer1,
        cardInfo.answer2,
        cardInfo.answer3,
        cardInfo.answer4,
      ].filter((answer) => answer?.trim() !== ""),
      explanation: cardInfo.explanation,
      card_type: cardTypeDict[cardInfo.card_type],
      visibility: cardInfo.visibility,
      image_url: showImageComponent ? cardInfo.image : null,
      image_url_back: showImageComponent ? cardInfo.imageBack : null,
      color: cardInfo.color,
      code_string: showCodeComponent ? cardInfo.code_string : null,
      language: cardInfo.language,
      tags: cardInfo.tags,
      categories: cardInfo.categories,
      is_image_background: cardInfo.is_image_background,
      is_back_image_background: cardInfo.is_back_image_background,
    };

    try {
      await cardService.createCard(cardData);
      dispatch(notifySuccess("Card created successfully!"));
      dispatch(fetchCards());
      handleClose();
      navigate("/dashboard?tab=Cards");
    } catch (error) {
      console.error("Failed to create card:", error);
    } finally {
      setIsSaveDisabled(false);
    }
  };

  useEffect(() => {
    const areRequiredFieldsFilled =
      cardInfo.categories?.length > 0 &&
      cardInfo.title.trim() &&
      isRichTextEmpty(cardInfo.description) === false;
    setCanBeSaved(areRequiredFieldsFilled);
  }, [cardInfo]);

  return (
    <Popup
      className="upload-ass-card-container"
      closePopup={null}
      closeFunc={() => setShowDangerModal(true)}
      withContainer={true}
      dataCy="upload-ass-card-popup"
    >
      <div className="upload-ass-card popup" ref={uploadRef}>
        {showDangerModal && (
          <AlertModal
            type="warning"
            onContinue={() => {
              handleClose();
            }}
            onCancel={() => {
              setShowDangerModal(false);
            }}
            title="Cancel Creating Card"
            message={`Are you sure you want to stop creating a card? This action is irreversible.`}
          />
        )}
        <div className="upload-header">
          Add Card
          <button
            className="upload-cross-sign"
            onClick={() => setShowDangerModal(true)}
          >
            <img src={CrossSign} alt="X" />
          </button>
        </div>

        <div className={`upload-body ${isOneColumn ? "vertical" : ""}`}>
          <div className="upload-body-left" ref={uploadBodyLeftRef}>
            <AssCard
              isMobile={isMobile}
              setCardInfo={setCardInfo}
              cardInfo={cardInfo}
              showImageComponent={showImageComponent}
              setShowImageComponent={setShowImageComponent}
              showCodeComponent={showCodeComponent}
              setShowCodeComponent={setShowCodeComponent}
              fileInputRef={fileInputRef}
              fileBackInputRef={fileBackInputRef}
              handleDrop={handleDrop}
              handleChange={handleChange}
              isSmallerMobile={isSmallerMobile}
              hasGenerateButton={true}
            />
          </div>
          <div className="upload-body-right">
            <div className="title">Card Preview</div>
            <div className="preview-and-colors">
              <div className="card-container-wrapper">
                <CardComponent
                  cardInfo={cardInfo}
                  answer1={cardInfo.answer1}
                  answer2={cardInfo.answer2}
                  answer3={cardInfo.answer3}
                  answer4={cardInfo.answer4}
                  showCodeComponent={false}
                  showImageComponent={showImageComponent}
                  image={cardInfo.image}
                  imageBack={cardInfo.imageBack}
                />
              </div>
              {!isOneColumn && cardInfo.card_type !== "Informational" && (
                <ColorPickerComponent
                  cardInfo={cardInfo}
                  setCardInfo={setCardInfo}
                />
              )}
            </div>
          </div>
        </div>
        <div className="upload-footer">
          <div className="upload-footer-buttons">
            <ButtonComponent
              text="Cancel"
              icon={false}
              level="secondary"
              handleClick={() => setShowDangerModal(true)}
            />
            <ButtonComponent
              level={canBeSaved && !isSaveDisabled ? "primary" : "disable"}
              text="Save"
              icon={false}
              handleClick={handleSave}
              dataCy="upload-ass-card-save-button"
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default UploadAssCard;
