import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "../../actions/global.action";
import { parseViews } from "../../utils/video";
import DropdownMenuPrivacy from "../DropdownMenuPrivacy/DropdownMenuPrivacy";
import PlaylistDropdown from "../PlaylistDropdown/PlaylistDropdown";
import AlertModal from "../../modals/AlertModal/AlertModal.jsx";
import trash from "../../assets/trash.svg";
import edit from "../../assets/edit.svg";
import "./styles.scss";
import { videoService } from "../../services/video.service.ts";
import ItemCard from "../ItemCard/ItemCard.jsx";
import TooltipComponent from "../TooltipComponent/TooltipComponent.jsx";

const MyVideo = ({ video, handleChange }) => {
  /*
  Stores the information of a single video in My Content tab
  Corresponds to the latest Figma design (May 15)
  video: a single video to be displayed
  handleChange: a function that leads to fetching videos since something was changed 
  in one of the videos (deleting of the visibility or visibility change)
  */

  const handleVisibilityChange = (selectedOption) => {
    const newVisibility = selectedOption.toLowerCase();

    videoService
      .changeVideoVisibility(video.id, newVisibility)
      .then(() => {
        dispatch(notifySuccess("Visibility changed successfully!"));
        handleChange();
      })
      .catch((error) => {
        dispatch(
          notifyError(
            `Error changing visibility! ${
              error.response ? error.response.data.message : error
            }`,
          ),
        );
        console.error(
          "Error:",
          error.response ? error.response.data : error.message,
        );
      });
  };

  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const [videoToDeleteId, setVideoToDeleteId] = useState(null);
  const [showDangerModal, setShowDangerModal] = useState(false);

  const handleEditVideo = (videoid) => {
    window.location.href = `/edit/video/${videoid}`;
  };

  const handleDeleteVideo = async (videoid) => {
    setDeleting(true);
    try {
      await videoService.deleteVideo(videoid);
      dispatch(notifySuccess("Video deleted successfully!"));
      handleChange();
    } catch (error) {
      console.error(
        "Error deleting video:",
        error.response ? error.response.data : error,
      );
      dispatch(
        notifyError(
          `Error deleting video! ${
            error.response ? error.response.data.message : error
          }`,
        ),
      );
    } finally {
      setDeleting(false);
      setShowDangerModal(false);
    }
  };

  return (
    <div
      className="my-content-item-component"
      key={video.id}
      data-cy="my-video-item"
    >
      {showDangerModal && (
        <AlertModal
          type="danger"
          onContinue={() => {
            handleDeleteVideo(videoToDeleteId);
          }}
          onCancel={() => {
            setShowDangerModal(false);
          }}
          title="Delete Video"
          message="Are you sure you want to delete this video? This action is irreversible."
          dataCy="delete-video-alert-modal"
        />
      )}
      {deleting && (
        <AlertModal
          type="loading"
          title="Deleting video..."
          message="Your video is being deleted. This might take a few seconds."
        />
      )}
      <ItemCard key={video.id} object={video} type="dashboard-video" />

      <div className="video-stats">
        <div className="views">Views: {parseViews(video.views || 0)}</div>
        <div className="vertical-line"></div>
        <div className="favorites">
          {" "}
          Favorites: {parseViews(video.likes_count || 0)}
        </div>
        <div className="vertical-line"></div>
        <div className="playlists">
          {" "}
          Playlists: {parseViews(video.playlists_count || 0)}
        </div>
      </div>

      <div className="video-status">
        <DropdownMenuPrivacy
          category="privacy"
          currentVisibility={video.visibility}
          handleVisibilityChange={handleVisibilityChange}
          dataCy="dropdown-privacy"
        />
      </div>

      <div className="video-controls">
        <div className="control-icon" onClick={() => handleEditVideo(video.id)}>
          <TooltipComponent
            tipText={"Edit"}
            placement={"bottom"}
            icon={edit}
            dataCy="edit-video-button"
          />
        </div>
        <div className="control-icon">
          <PlaylistDropdown
            video={video}
            itemId={video.id}
            addFunction="video"
            isDashboard={true}
          />
        </div>
        <div
          className="control-icon"
          onClick={() => {
            setVideoToDeleteId(video.id);
            setShowDangerModal(true);
          }}
        >
          <TooltipComponent
            tipText={"Delete"}
            placement={"bottom"}
            icon={trash}
            dataCy="delete-video-button"
          />
        </div>
      </div>
    </div>
  );
};

export default MyVideo;
