import React, { useEffect, useRef, useState } from "react";
import chevronLeft from "../../assets/chevron-left.svg";
import chevronRight from "../../assets/chevron-right.svg";
import "./styles.scss";
import ItemCard from "../ItemCard/ItemCard";
import { Skeleton } from "@mui/material";
const ListVideoComponent = ({
  items,
  type = "videos",
  heading = "Popular",
  key_prefix = "object",
  showSeeAll = false,
  isSkeleton = false,
}) => {
  /*  
      A component that displays a list of videos/playlists horizontally 
        - items: list of either videos/playlists
        - type: 'videos' or 'playlist' to display different items for each
        - heading: heading of the list
    */

  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const itemsListRef = useRef(null);
  if (isSkeleton) {
    items = new Array(10).fill(null).map((_, index) => ({
      id: `${index}`,
    }));
  }

  useEffect(() => {
    if (!itemsListRef.current) return;

    const checkButtonsVisibility = () => {
      const { offsetWidth, scrollLeft, scrollWidth } = itemsListRef.current;
      setShowNextButton(Math.ceil(offsetWidth + scrollLeft) < scrollWidth);
      setShowPrevButton(scrollLeft > 0);
    };

    checkButtonsVisibility();
    window.addEventListener("resize", checkButtonsVisibility);

    itemsListRef.current.addEventListener("scroll", checkButtonsVisibility);
    return () => {
      window.removeEventListener("resize", checkButtonsVisibility);
      itemsListRef.current?.removeEventListener(
        "scroll",
        checkButtonsVisibility,
      );
    };
  }, [items]);

  const scrollPrev = () => {
    itemsListRef.current.scrollBy({
      left: -300,
      behavior: "smooth",
    });
  };

  const scrollNext = () => {
    itemsListRef.current.scrollBy({
      left: 300,
      behavior: "smooth",
    });
  };

  if (!isSkeleton && items.length === 0) {
    return null;
  }

  return (
    <div className="list-videos">
      {!isSkeleton ? (
        heading && <span className="video-list-title">{heading}</span>
      ) : (
        <Skeleton
          variant="text"
          width={350}
          height={40}
          style={{ borderRadius: "8px" }}
        />
      )}
      {showSeeAll && !isSkeleton && (
        <a href="#seeAll" className="see-all-link">
          See All
        </a>
      )}
      <div className="video-list hidden-scrollbar" ref={itemsListRef}>
        {["videos", "cards"].includes(type)
          ? items.map((video) => (
              <div className="single-video" key={key_prefix + video.id}>
                <ItemCard
                  is_horizontal={true}
                  object={video}
                  level="large"
                  isSkeleton={isSkeleton}
                />
              </div>
            ))
          : type === "playlists"
            ? items.map((playlist) => (
                <>
                  {playlist.card_count + playlist.video_count === 0 ? null : (
                    <div
                      className="single-video"
                      key={key_prefix + playlist.id}
                    >
                      <ItemCard
                        is_horizontal={true}
                        object={playlist}
                        level="large"
                        type="playlist"
                        isSkeleton={isSkeleton}
                      />
                    </div>
                  )}
                </>
              ))
            : items.map((item) => {
                if (item.type === "video" || isSkeleton) {
                  return (
                    <div className="single-video" key={key_prefix + item.id}>
                      <ItemCard
                        is_horizontal={true}
                        object={item}
                        level="large"
                        type="video"
                        isSkeleton={isSkeleton}
                      />
                    </div>
                  );
                } else if (item.type === "playlist") {
                  return (
                    <div className="single-video" key={key_prefix + item.id}>
                      <ItemCard
                        is_horizontal={true}
                        object={item}
                        level="large"
                        type="playlist"
                        isSkeleton={isSkeleton}
                      />
                    </div>
                  );
                }
              })}
        {showPrevButton && !isSkeleton && (
          <button
            className="scroll-nav-button prev-button"
            onClick={scrollPrev}
            style={{ top: `${heading ? "105px" : "75px"}` }}
          >
            <img src={chevronLeft} alt="Previous" />
          </button>
        )}
        {showNextButton && !isSkeleton && (
          <button
            className="scroll-nav-button next-button"
            onClick={scrollNext}
            style={{ top: `${heading ? "105px" : "75px"}` }}
          >
            <img src={chevronRight} alt="Next" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ListVideoComponent;
