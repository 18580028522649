import React, { useRef, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import videojs from "video.js";
import { TIME_DELAY_NAVIGATE_TO_NEXT_ITEM } from "../../utils/constants";

import VideoJS from "./VideoJS.jsx";
import CustomTimeDisplay from "./CustomTimeDisplay.js";
import AutoplayButton from "./AutoplayButton.js";
// import PlaybackSpeedButton from "./PlaybackSpeedButton";
import { videoService } from "../../services/video.service.ts";
import {
  setVideoSpeed,
  setAudioLevel,
  setMute,
  setAutoplay,
} from "../../actions/player.js";
import { VIEW_VIDEO_PERCENTAGE } from "../../utils/constants.js";
import "./styles.scss";

function Video({
  id,
  video_type,
  video_url,
  isEmbedded = false,
  setSelectedOption = { setSelectedOption },
  isDetailMode,
  poster,
  isVisible,
  navigateToNextItem,
}) {
  /**
   * Video Component
   *
   * A React component for video playback using video.js, integrated with Redux.
   *
   * Features:
   * - Responsive video playback with controls (play, pause, volume, rate, fullscreen).
   * - Supports autoplay, mute, and keyboard shortcuts.
   * - Tracks watch progress and updates history.
   *
   * Props:
   * - id: Unique video identifier.
   * - video_type: "landscape" or "portrait".
   * - video_url: Source URL.
   * - isEmbedded: Boolean for embedded state.
   * - poster: Poster image URL.
   * - isVisible: Boolean for visibility.
   *
   * Usage:
   * <Video id="video123" video_type="landscape" video_url="..." poster="..." isVisible={true} />
   */
  const dispatch = useDispatch();
  const location = useLocation();
  const playerRef = useRef(null);
  const vidContainerRef = useRef();

  const currentPlaylist = useSelector(
    (state) => state.playlist.currentPlaylist,
  );
  const showPlaylistWatch = useSelector(
    (state) => state.playlist.watchingPlaylist,
  );
  const videoSpeed = useSelector((state) => state.player.videoSpeed);
  const audioLevel = useSelector((state) => state.player.audioLevel);
  const isMuted = useSelector((state) => state.player.isMuted);
  const isAutoplay = useSelector((state) => state.player.isAutoplay);
  const showModal = useSelector((state) => state.global.showModal);

  const [refresh, setRefresh] = useState(false);

  const aspectRatio = video_type === "landscape" ? "16:9" : "9:16";
  let viewed = false;
  let watched = false;
  let watchProgressId = null;

  const getPlaylistId = useCallback(() => {
    if (location.pathname.includes("/playlist/")) {
      const pathPart = location.pathname.split("/playlist/")[1];
      return pathPart.endsWith("/") ? pathPart.slice(0, -1) : pathPart;
    }
    return null;
  }, [location.pathname]);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    preload: "none",
    fluid: true,
    fill: true,
    aspectRatio,
    poster,
    enableSmoothSeeking: true,
    playbackRates: [0.75, 1, 1.25, 1.5, 1.75, 2],
    controlBar: {
      remainingTimeDisplay: { displayNegative: false },
      pictureInPictureToggle: true,
      children: [
        "playToggle",
        "volumePanel",
        "customControlSpacer",
        "customTimeDisplay",
        "progressControl",
        "liveDisplay",
        "remainingTimeDisplay",
        "customControlSpacer",
        "playbackRateMenuButton",
        "chaptersButton",
        "subtitlesButton",
        "captionsButton",
        "audioTrackButton",
        // "PlaybackSpeedButton",
        "fullscreenToggle",
        "AutoplayButton",
      ],
    },
    sources: [{ src: video_url, type: "video/mp4" }],
    errorDisplay: false,
  };

  const handlePlayerReady = useCallback(
    (player) => {
      playerRef.current = player;

      player.reloadSourceOnError({
        getSource: (reload) => reload({ src: video_url, type: "video/mp4" }),
        errorInterval: 5,
      });

      player.on("touchstart", () => {
        player.paused() ? player.play() : player.pause();
      });

      player.on("loadstart", () => {
        player.hasEnded = false;
      });

      player.on("ended", () => {
        player.hasEnded = true;
        if (isAutoplay && navigateToNextItem) {
          setTimeout(() => {
            navigateToNextItem();
          }, TIME_DELAY_NAVIGATE_TO_NEXT_ITEM);
        }
      });
      const handleAutoplayChange = (status) => {
        dispatch(setAutoplay(status));
      };

      player.on("timeupdate", async () => {
        const timeWatched = getPlayedTime(player);
        if (timeWatched.total > 0 && !viewed) {
          viewed = true;
          try {
            const playlistId =
              showPlaylistWatch === "watch"
                ? currentPlaylist.object_id
                : getPlaylistId();
            const response = await videoService.addToWatchHistory(
              id,
              playlistId,
            );
            watchProgressId = response.watch_progress_id;
          } catch (error) {
            console.error("Error:", error);
          }
        }
        if (timeWatched.percent >= VIEW_VIDEO_PERCENTAGE) {
          handleWatch(timeWatched.percent);
        }
        watchProgressId && updateWatchProgress(timeWatched.percent);
      });

      player.playbackRate(videoSpeed);
      player.volume(audioLevel);
      player.muted(isMuted);

      player.on("ratechange", () => {
        dispatch(setVideoSpeed(player.playbackRate()));
      });

      player.on("volumechange", () => {
        dispatch(setAudioLevel(player.volume()));
        dispatch(setMute(player.muted()));
      });

      player.on("contextmenu", (event) => {
        event.preventDefault();
      });

      //NOTE: The following code is commented out because it's not needed for now
      // const PlaylistButton = videojs.getComponent('Button');
      // const playlistButton = new PlaylistButton(player, {
      //   text: 'Playlist',
      // });
      // playlistButton.controlText('Playlist');
      // playlistButton.el().innerHTML = `<img src=${playlistIcon} alt="Playlist" style="width: 24px; height: 24px;" />`;
      // playlistButton.on('click', () => {
      //   navigate('/home');
      // });
      // player.controlBar.addChild(playlistButton, {}, 15); // Add at the start of the control bar

      if (!player.controlBar.getChild("CustomTimeDisplay")) {
        videojs.registerComponent("CustomTimeDisplay", CustomTimeDisplay);
        player.controlBar.addChild("CustomTimeDisplay", {}, 4);
      }

      // player.controlBar.removeChild("PlaybackSpeedButton");
      // videojs.registerComponent("PlaybackSpeedButton", PlaybackSpeedButton);
      // player.controlBar.addChild(
      //   "PlaybackSpeedButton",
      //   { videoSpeed, onSpeedChange: handleSpeedChange },
      //   11,
      // );

      player.controlBar.removeChild("AutoplayButton");
      videojs.registerComponent("AutoplayButton", AutoplayButton);
      player.controlBar.addChild(
        "AutoplayButton",
        { isAutoplay, onAutoplayChange: handleAutoplayChange },
        11,
      );

      setRefresh(true);
    },
    [
      dispatch,
      video_url,
      videoSpeed,
      audioLevel,
      isMuted,
      isAutoplay,
      currentPlaylist,
      showPlaylistWatch,
      getPlaylistId,
      navigateToNextItem,
    ],
  );

  const getPlayedTime = (player) => {
    let totalPlayed = 0;
    const played = player.played();

    for (let i = 0; i < played.length; i++) {
      totalPlayed += played.end(i) - played.start(i);
    }

    return {
      total: totalPlayed,
      percent: totalPlayed / player.duration(),
    };
  };

  const handleWatch = async (progress) => {
    if (!watched) {
      watched = true;
      try {
        await videoService.updateViews(id, progress, watchProgressId);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const updateWatchProgress = async (progress) => {
    try {
      await videoService.updateWatchProgress(id, progress, watchProgressId);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (playerRef.current) {
      const currentSpeed = playerRef.current.playbackRate();
      if (currentSpeed !== videoSpeed) {
        playerRef.current.playbackRate(videoSpeed);
      }
    }
  }, [videoSpeed]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.volume(audioLevel);
      playerRef.current.muted(isMuted);
    }
  }, [audioLevel, isMuted]);

  useEffect(() => {
    if (playerRef.current && isVisible && isAutoplay) {
      playerRef.current.play().catch((error) => {
        if (error.name === "NotAllowedError") {
          console.warn(
            `Failed to play: blocked by Chrome's autoplay policy -- Detail: "${error}"`,
          );
        } else if (error.name === "AbortError") {
          console.warn(
            `Failed to play: a video is paused before it started playing -- Detail: "${error}"`,
          );
        }
      });
    }
    if (playerRef.current && !isVisible) {
      playerRef.current.pause();
    }
  }, [isVisible, isAutoplay, refresh]);

  useEffect(() => {
    if (playerRef.current) {
      const autoplayComponent =
        playerRef.current.controlBar.getChild("AutoplayButton");
      if (autoplayComponent) {
        autoplayComponent.updateState(isAutoplay);
        playerRef.current.autoplay(isAutoplay);
      }
    }
  }, [isAutoplay]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (
        (event.target.classList.contains("tiptap") &&
          event.target.classList.contains("ProseMirror")) ||
        !isVisible ||
        event.target.classList.contains("text-input-component-input")
      ) {
        return;
      }

      if (showModal && event.key === " ") {
        event.preventDefault();
        return;
      }

      if (
        !playerRef.current ||
        playerRef.current?.paused === undefined ||
        showModal
      ) {
        return;
      }

      const currentTime = playerRef.current.currentTime();

      if (event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
        return;
      }

      switch (event.key.toLowerCase()) {
        case "f":
          toggleFullscreen();
          event.preventDefault();
          break;
        case "m":
          dispatch(setMute(!playerRef.current.muted()));
          event.preventDefault();
          break;
        case " ":
        case "k":
          togglePlayPause();
          event.preventDefault();
          break;
        case "arrowright":
        case "l":
          playerRef.current.currentTime(currentTime + 5);
          event.preventDefault();
          break;
        case "arrowleft":
        case "j":
          playerRef.current.currentTime(currentTime - 5);
          event.preventDefault();
          break;
        default:
          break;
      }
    };

    const toggleFullscreen = () => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (playerRef.current.requestFullscreen) {
        playerRef.current.requestFullscreen().catch((err) => {
          console.error(
            `Failed to enter fullscreen mode: ${err.message} (${err.name})`,
          );
        });
      } else if (playerRef.current.webkitRequestFullscreen) {
        playerRef.current.webkitRequestFullscreen();
      } else if (playerRef.current.mozRequestFullScreen) {
        playerRef.current.mozRequestFullScreen();
      } else if (playerRef.current.msRequestFullscreen) {
        playerRef.current.msRequestFullscreen();
      }
    };

    const togglePlayPause = () => {
      if (!playerRef.current?.paused()) {
        playerRef.current?.pause();
      } else if (isVisible) {
        playerRef.current?.play();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isVisible, showModal, dispatch]);

  useEffect(() => {
    // Add visibility change handler
    const handleVisibilityChange = () => {
      if (document.hidden && playerRef.current) {
        playerRef.current.pause();
      }
    };

    // Add blur handler
    const handleBlur = () => {
      if (playerRef.current) {
        playerRef.current.pause();
      }
    };

    // Add event listeners
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("blur", handleBlur);

    // Cleanup function
    return () => {
      // Remove event listeners
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("blur", handleBlur);

      // Stop video when component unmounts
      if (playerRef.current) {
        playerRef.current.pause();
      }
    };
  }, []);

  const videoOrientationClassName =
    video_type === "portrait" ? "video-portrait" : "video-landscape";
  const videoClassName = `${videoOrientationClassName}${isEmbedded ? "-embedded" : ""} video video-container`;

  return (
    <div ref={vidContainerRef} className={videoClassName}>
      <VideoJS
        key={video_url}
        options={videoJsOptions}
        onReady={handlePlayerReady}
      />
    </div>
  );
}

export default Video;
