import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { userService } from "../../services/user.service.ts";
import { videoService } from "../../services/video.service.ts";
import { cardService } from "../../services/card.service.ts";
import { playlistService } from "../../services/playlist.service.ts";
import { renderContent } from "../../utils/editor.jsx";
import { formatSimpleDate, parseViews } from "../../utils/video";

import TabComponent from "../../components/TabComponent/TabComponent.jsx";
import FilterList from "../../components/FilterList/FilterList.jsx";
import AuthorAvatar from "../../components/AuthorAvatar/AuthorAvatar.jsx";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent.jsx";
import ListVideoComponent from "../../components/ListVideoComponent/ListVideoComponent.jsx";
import NotFoundPage from "../NotFoundPage/NotFoundPage.jsx";
import { Skeleton } from "@mui/material";

import "./styles.scss";

const CreatorPage = () => {
  /*
    It's a creator profile page showing the key information about the creator, their statistics and other relevant information

    todo for backend:
    1. Create username on the backend and use it for url instead of id
    2. Edit an endpoint, which would return data about the user based on their id
        2.1. Avatar
        2.2. Description
        2.3. Number of subscribers
        2.4. When the user joined
        2.5. Username
    3. Create an endpoint that checks if currently auth user is subscribed for the other user
        3.1. maybe just list of all the channels on which they are subscribed
    4. Add more information for playlists
        4.1. Number of views

    todo for frontend:
    1. Redefine routing based on username
    */

  const location = useLocation();
  const navigate = useNavigate();

  const { channelUsername } = useParams();
  const userId = useSelector((state) => state.auth.user?.id);

  const TABS = ["All", "Videos", "Cards", "Playlists"];
  const [activeTab, setActiveTab] = useState("All");

  const [user, setUser] = useState({
    data: null,
    channelUuid: "",
    name: "",
    username: "",
    channelName: "",
    joinedDate: "",
    description: "",
    categories: [],
  });
  const [videos, setVideos] = useState({
    data: [],
    categories: [],
    filteredData: [],
    popularData: [],
    trendingData: [],
    allData: [],
    loaded: false,
  });
  const [cards, setCards] = useState({
    data: [],
    categories: [],
    filteredData: [],
    loaded: false,
  });
  const [playlists, setPlaylists] = useState({
    data: [],
    categories: [],
    filteredData: [],
    loaded: false,
  });

  const [selectedCategories, setSelectedCategories] = useState(["All"]);
  const [notFound, setNotFound] = useState(null);

  const isLoaded = (section) => {
    switch (section) {
      case "All":
        return videos.loaded && cards.loaded && playlists.loaded;
      case "Videos":
        return videos.loaded;
      case "Cards":
        return cards.loaded;
      case "Playlists":
        return playlists.loaded;
      default:
        return false;
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");

    if (tab && TABS.includes(tab)) {
      setActiveTab(tab);
      setSelectedCategories(["All"]);
    } else {
      setActiveTab("All");
    }
  }, [location.search]);

  useEffect(() => {
    fetchUser();
  }, [channelUsername]);

  const fetchUser = async () => {
    try {
      setNotFound(null);
      const response = await userService.getUser(channelUsername.toLowerCase());
      setUser({
        ...user,
        data: response,
        channelUuid: response.id,
        name: response.first_name + " " + response.last_name,
        username: response.username,
        channelName: response.channel_name,
        joinedDate: formatSimpleDate(new Date(response.created_at), {
          month: "short",
          year: "numeric",
        }),
        description: formatDescription(response.introduction),
      });
    } catch (error) {
      setNotFound("user");
      console.error("Error fetching channel info:", error);
    }
  };

  const formatDescription = (description) => {
    try {
      const jsonDesc = JSON.parse(description);
      const renderedDesc = renderContent(jsonDesc);
      if (renderedDesc[0].props.children) {
        return renderContent(jsonDesc);
      } else {
        return "No channel description available.";
      }
    } catch (err) {
      return description?.length > 0
        ? description
        : "No channel description available.";
    }
  };

  useEffect(() => {
    if (user.channelUuid) {
      fetchVideos();
      fetchCards();
      fetchPlaylists();
    }
  }, [user.channelUuid]);

  const fetchVideos = async () => {
    try {
      const response = await videoService.getUserVideos(user.channelUuid);
      const videoCategories = response.reduce((acc, item) => {
        const categoryNames = item?.categories?.map(
          (category) => category.name,
        );
        return acc.concat(categoryNames);
      }, []);
      setVideos({
        ...videos,
        data: response,
        categories: ["All", ...uniqueSorted(videoCategories)],
        loaded: true,
      });
    } catch (error) {
      console.error("Error fetching user videos:", error);
    }
  };

  const fetchCards = async () => {
    try {
      const response = await cardService.getChannelCards(user.channelUuid);
      const cardCategories = response.reduce((acc, item) => {
        const categoryNames = item?.categories?.map(
          (category) => category.name,
        );
        return acc.concat(categoryNames);
      }, []);
      setCards({
        ...cards,
        data: response,
        categories: ["All", ...uniqueSorted(cardCategories)],
        loaded: true,
      });
    } catch (error) {
      console.error("Error fetching user cards:", error);
    }
  };

  const fetchPlaylists = async () => {
    try {
      const response = await playlistService.getUserPlaylists(user.channelUuid);
      response.sort((a, b) => b.video_count - a.video_count);
      const playlistCategories = response.reduce((acc, item) => {
        const categoryNames = item?.categories?.map(
          (category) => category.name,
        );
        return acc.concat(categoryNames);
      }, []);
      setPlaylists({
        ...playlists,
        data: response,
        categories: ["All", ...uniqueSorted(playlistCategories)],
        loaded: true,
      });
    } catch (error) {
      console.error("Error fetching user playlists:", error);
    }
  };

  const uniqueSorted = (data) =>
    [...new Set(data)]
      .filter(
        (category) => category && category !== "All" && category.trim() !== "",
      )
      .sort();

  useEffect(() => {
    if (videos.data && videos.data.length > 0) {
      filterVideos();
    }
  }, [videos.data, selectedCategories]);

  const filterVideos = () => {
    if (selectedCategories.includes("All")) {
      setVideos({ ...videos, filteredData: videos.data });
    } else {
      let res = [];
      videos.data.map((video) => {
        if (
          video?.categories &&
          video?.categories.some((category) =>
            selectedCategories.includes(category.name),
          )
        ) {
          res.push(video);
        }
      });
      setVideos({ ...videos, filteredData: res });
    }
  };

  useEffect(() => {
    if (cards.data && cards.data.length > 0) {
      filterCards();
    }
  }, [cards.data, selectedCategories]);

  const filterCards = () => {
    if (selectedCategories.includes("All")) {
      setCards({ ...cards, filteredData: cards.data });
    } else {
      let res = [];
      cards.data.map((card) => {
        if (
          card?.categories &&
          card?.categories.some((category) =>
            selectedCategories.includes(category.name),
          )
        ) {
          res.push(card);
        }
      });
      setCards({ ...cards, filteredData: res });
    }
  };

  useEffect(() => {
    if (playlists.data.length > 0) {
      filterPlaylists();
    }
  }, [playlists.data, selectedCategories]);

  const filterPlaylists = () => {
    if (selectedCategories.includes("All")) {
      setPlaylists({
        ...playlists,
        filteredData: playlists.data,
      });
    } else {
      let resultedPlaylists = [];
      playlists.data.map((media) => {
        if (
          media?.categories &&
          media?.categories.some((category) =>
            selectedCategories.includes(category.name),
          )
        ) {
          resultedPlaylists.push(media);
        }
      });
      setPlaylists({
        ...playlists,
        filteredData: resultedPlaylists,
      });
    }
  };

  useEffect(() => {
    if (videos.filteredData.length > 0) {
      sortVideos();
    }
  }, [videos.filteredData]);

  const sortVideos = () => {
    const popular = videos.filteredData
      .filter((video) => video.views)
      .sort((a, b) => b.views - a.views);

    const now = new Date();
    const trending = videos.filteredData
      .filter((video) => video.views && video.date)
      .map((video) => {
        const daysOld = Math.ceil(
          (now - new Date(video.date)) / (1000 * 60 * 60 * 24),
        );
        return {
          ...video,
          trendScore: video.views / daysOld,
        };
      })
      .sort((a, b) => b.trendScore - a.trendScore);

    const all = videos.filteredData
      .filter((video) => video.date)
      .sort((a, b) => new Date(b.date) - new Date(a.date));

    setVideos({
      ...videos,
      popularData: popular,
      trendingData: trending,
      allData: all,
    });
  };

  const getCategoriesByTab = () => {
    const allCategories = [
      ...videos.categories,
      ...cards.categories,
      ...playlists.categories,
    ];
    const categoriesMap = {
      All: ["All", ...uniqueSorted(allCategories)],
      Videos: videos.categories,
      Cards: cards.categories,
      Playlists: playlists.categories,
    };

    const categories = categoriesMap[activeTab] || [];
    return categories.length > 1 ? categories : [];
  };

  return notFound ? (
    <NotFoundPage pageType={notFound} />
  ) : (
    <div className="creator-page">
      <div className="creator-page-top">
        <div className="avatar">
          <AuthorAvatar author={user.data} isSkeleton={!isLoaded("All")} />
        </div>
        <div className="description">
          {isLoaded("All") ? (
            <div className="name" data-cy="name">
              {user.channelName && user.channelName.length > 0
                ? user.channelName
                : user.name}
              {user.username && (
                <div className="username">@{user.username}</div>
              )}
            </div>
          ) : (
            <div className="name">
              <Skeleton height="75%" width={200} />
              <Skeleton height="25%" width={200} />
            </div>
          )}

          {isLoaded("All") ? (
            <div className="statistics">
              <div> {parseViews(videos.data.length || 0)} Videos</div>
              <div className="vertical-line"></div>
              <div> {parseViews(cards.data.length || 0)} Cards</div>
              <div className="vertical-line"></div>
              <div> {parseViews(playlists.data.length || 0)} Playlists</div>
              {user.joinedDate && (
                <>
                  <div className="vertical-line"></div>
                  <div> {`Joined: ${user.joinedDate}`}</div>
                </>
              )}
            </div>
          ) : (
            <div className="statistics">
              <Skeleton height="100%" width={75} />
              <div className="vertical-line"></div>
              <Skeleton height="100%" width={75} />
              <div className="vertical-line"></div>
              <Skeleton height="100%" width={75} />
              <div className="vertical-line"></div>
              <Skeleton height="100%" width={75} />
            </div>
          )}

          {isLoaded("All") ? (
            <div className="description" data-cy="description">
              {user.description}
            </div>
          ) : (
            <div className="description">
              <Skeleton height="100%" width={400} />
            </div>
          )}

          {userId === user.channelUuid && (
            <div className="subscribe">
              <ButtonComponent
                text={"Edit Bio"}
                level="primary"
                icon={false}
                handleClick={() => navigate("/settings?tab=PublicProfile")}
                size="small"
                dataCy="edit-bio-button"
              />
            </div>
          )}
        </div>
      </div>

      <div className="video-playlist-filtering">
        <TabComponent tabs={TABS} defaultTab="All" />
      </div>

      {isLoaded("All") ? (
        <div className="categories-filtering">
          <FilterList
            categories={getCategoriesByTab()}
            selectedCategories={selectedCategories}
            handleClick={(category) => setSelectedCategories([category])}
          />
        </div>
      ) : (
        <div className="categories-filtering">
          <div style={{ height: "50px" }}></div>
        </div>
      )}

      <div className="videos">
        <div className="Popular">
          {(!isLoaded("All") || videos.popularData.length > 0) &&
            (activeTab === "All" || activeTab === "Videos") && (
              <ListVideoComponent
                items={videos.popularData}
                heading="Popular"
                isSkeleton={!isLoaded(activeTab)}
                key_prefix="popular-video-"
              />
            )}
        </div>
        <div className="Trending">
          {!isLoaded("All") ||
            (videos.trendingData.length > 0 &&
              (activeTab === "All" || activeTab === "Videos") && (
                <ListVideoComponent
                  items={videos.trendingData}
                  heading="Trending"
                  isSkeleton={!isLoaded(activeTab)}
                  key_prefix="trending-video-"
                />
              ))}
        </div>
        <div className="All">
          {(activeTab === "All" || activeTab === "Videos") &&
            (!isLoaded("All") || videos.allData.length > 0 ? (
              <ListVideoComponent
                items={videos.allData}
                heading="All"
                isSkeleton={!isLoaded(activeTab)}
              />
            ) : activeTab === "Videos" && isLoaded("Videos") ? (
              <div className="no-content-text">
                {" "}
                @{channelUsername.toLowerCase()} doesn’t have any videos.
              </div>
            ) : (
              playlists.filteredData.length === 0 &&
              isLoaded("Playlists") &&
              cards.filteredData.length === 0 &&
              isLoaded("Cards") && (
                <div className="no-content-text">
                  {" "}
                  @{channelUsername.toLowerCase()} doesn’t have any content or
                  playlist.
                </div>
              )
            ))}
        </div>
        <div>
          {["Cards", "All"].includes(activeTab) &&
            (!isLoaded("Cards") || cards.filteredData.length > 0 ? (
              <ListVideoComponent
                items={cards.filteredData}
                author={user.data}
                heading="Cards"
                type={"cards"}
                isSkeleton={!isLoaded(activeTab)}
              />
            ) : (
              activeTab === "Cards" &&
              isLoaded("Cards") && (
                <div className="no-content-text">
                  {" "}
                  @{channelUsername.toLowerCase()} doesn’t have any cards.
                </div>
              )
            ))}
        </div>
        <div>
          {["Playlists", "All"].includes(activeTab) &&
            (!isLoaded("Playlists") || playlists.filteredData.length > 0 ? (
              <ListVideoComponent
                items={playlists.filteredData}
                author={user.data}
                heading="Playlists"
                type={"playlists"}
                isSkeleton={!isLoaded(activeTab)}
              />
            ) : (
              activeTab === "Playlists" &&
              isLoaded("Playlists") && (
                <div className="no-content-text">
                  {" "}
                  @{channelUsername.toLowerCase()} doesn’t have any playlists.
                </div>
              )
            ))}
        </div>
      </div>
    </div>
  );
};

export default CreatorPage;
