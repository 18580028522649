import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useFeedCardObservers } from "../../hooks/useFeedCardObservers";
import useWindowWidth from "../../hooks/useWindowWidth";
import useWindowHeight from "../../hooks/useWindowHeight";
import { showSignup } from "../../actions/global.action";

import FeedCard from "../../components/FeedComponents/FeedCard";
import InfoCard from "../../components/FeedComponents/InfoCard";

import chevronDown from "../../assets/chevron-down.svg";
import chevronUp from "../../assets/chevron-up.svg";
import chevronUpUp from "../../assets/chevron-up-up.svg";
import "./styles.scss";

import { cardTypeDict } from "../../utils/constants";

const FeedContainer = ({
  data,
  type,
  isMyTab,
  isPrivatePage,
  isEmpty,
  showSignUpForm,
  playlistTitle,
  cardRefs,
  totalNumOfCards,
  currentVideoIndex = 0,
}) => {
  /*
    Feed container is a component used to display a list of cards to the user.
    It's used to display Feed, Playlist Page, Video Page. Also, it's embedded into My Playlists tab and VideoPreview.
    It's mostly focused on the frontend part of displaying cards correctly.

    Props: 
    - data: array - list of videos to display (can be extanded to store not only videos)
    - type: string - used to determine the type of the page (Feed, Playlist, Video, Card)
    - isMyTab: boolean - used to display a specific style for the My Tab
    - isPrivatePage: boolean - used to display a specific style for the Private Page
    - isEmpty: boolean - used to display a specific style for the Empty Page
    - isAuthCard: boolean - used to display a specific style for the Auth Card
  */

  const dispatch = useDispatch();

  // Variables related to window dimension and responsiveness
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  const [deviceType, setDeviceType] = useState("desktop");

  // Dispatch store variable to override and show modal
  const showModal = useSelector((state) => state.global.showModal);

  // State variables to control detail mode. Not put in FeedCard in order persist mode across scrolling
  const [isDetailMode, setIsDetailMode] = useState(false);
  const [selectedOption, setSelectedOption] = useState("description");

  const feedContainerRef = useRef(null);

  // State variable that determines visibility. Permeates to all children FeedCard, Playlist, Video, Card
  const [currFeedCardIndex, setCurrFeedCardIndex] = useState(0);
  const currFeedCardIndexRef = useRef(0); // just for logging purpose in useFeedCardObserver
  const isObserverPausedRef = useRef(false); // pauses useFeedCardObservers when navigating to far items

  const [isUpChevronHovered, setIsUpChevronHovered] = useState(false);

  useFeedCardObservers(
    cardRefs,
    setCurrFeedCardIndex,
    currFeedCardIndexRef,
    isObserverPausedRef,
  );

  useEffect(() => {
    if (windowWidth > 1440) {
      setDeviceType("desktop");
    } else if (windowWidth > 1280) {
      setDeviceType("macbook-pro");
    } else if (windowWidth > 770) {
      setDeviceType("macbook-air");
    } else {
      setDeviceType("mobile");
    }

    if (windowHeight < 700) {
      setDeviceType("mobile");
    }
  }, [windowWidth, windowHeight]);

  let isThrottled = false;
  const handleChevron = (newIndex, oneStepDown = false) => {
    if (isThrottled) return;

    // If oneStepOnly is true, we'll move just one step from current index
    const targetIndex = oneStepDown ? currFeedCardIndex + 1 : newIndex;
    const container = feedContainerRef.current;
    const targetElement = cardRefs.current[targetIndex - 1]?.current;

    if (container && targetElement) {
      container.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });

      isThrottled = true;
      setTimeout(() => {
        isThrottled = false;
      }, 500);
    }
  };

  useEffect(() => {
    if (currentVideoIndex !== 0) {
      navigateToItem(currentVideoIndex, 1);
    }
  }, [currentVideoIndex]);

  // Function that permeates to FeedCard, Playlist, ItemCard for navigation in Playlist sidebar
  const navigateToItem = (newIndex, nowIndex) => {
    // Temporarily disable observers since the next item can be far up/down the feed
    isObserverPausedRef.current = true;
    handleChevron(newIndex);
    setCurrFeedCardIndex(newIndex); // Hard code the new index instead of relying on FeedCardObserver

    setTimeout(() => {
      isObserverPausedRef.current = false; // Re-enable observers
    }, 1000);
  };

  const navigateToNextItem = () => {
    const chevronDownButton = document.getElementById("chevron-down");
    if (chevronDownButton) {
      chevronDownButton.click();
    }
  };

  const handleGlobalKeyDown = (event) => {
    if (showModal) {
      return;
    }

    if (
      (event.target.classList.contains("tiptap") &&
        event.target.classList.contains("ProseMirror")) ||
      event.target.classList.contains("text-input-component-input")
    ) {
      return;
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();
      const chevronDownButton = document.getElementById("chevron-down");
      if (chevronDownButton) {
        chevronDownButton.click();
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      const chevronUpButton = document.getElementById("chevron-up");
      if (chevronUpButton) {
        chevronUpButton.click();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleGlobalKeyDown);

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, []);

  let isScrollThrottled = false;
  let lastScrollTop = 0;
  const handleScroll = () => {
    if (isScrollThrottled || !showSignUpForm) return;
    const container = feedContainerRef.current;
    if (!container) return;

    const scrollTop = container.scrollTop;

    // Only proceed if scrolling down
    if (scrollTop > lastScrollTop) {
      if (isScrollThrottled) return;
      isScrollThrottled = true;

      // Dispatch action
      dispatch(showSignup());

      // Reset throttle after delay
      setTimeout(() => {
        isScrollThrottled = false;
      }, 500);
    }

    // Update last scroll position
    lastScrollTop = scrollTop;
  };

  useEffect(() => {
    const container = feedContainerRef.current;
    if (container && currFeedCardIndex === data?.length) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [showSignUpForm, currFeedCardIndex, data?.length, dispatch]);

  if (isPrivatePage) {
    return (
      <div className="feed-container">
        <div className="feed-container-card">
          <InfoCard type="privatePage" />
        </div>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div
        ref={feedContainerRef}
        className={`feed-container hidden-scrollbar ${isMyTab ? " my-tab" : ""}${isDetailMode && deviceType !== "mobile" ? " focus-mode" : ""}${data?.length <= 1 ? " one-card" : ""}`}
      >
        <div className="feed-container-card">
          <InfoCard type="noVideos" />
        </div>
      </div>
    );
  }

  return (
    <div
      ref={feedContainerRef}
      className={`feed-container hidden-scrollbar${isMyTab ? " my-tab" : ""}${isDetailMode && deviceType !== "mobile" ? " focus-mode" : ""}${data?.length <= 1 ? " one-card" : ""}${type === "video-preview" ? " video-preview" : ""}`}
    >
      {data?.map((video, index) => (
        <div
          className={`feed-container-card${type === "video-preview" ? " video-preview-card" : ""}`}
          key={video.id + index}
          ref={cardRefs.current[index]}
        >
          <FeedCard
            type={
              video?.card_type === "video"
                ? "video"
                : Object.values(cardTypeDict).includes(video?.card_type)
                  ? "card"
                  : type
            }
            data={data}
            video={video}
            deviceType={deviceType}
            isMyTab={isMyTab}
            isDetailMode={isDetailMode && deviceType !== "mobile"}
            setIsDetailMode={setIsDetailMode}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            navigateToItem={navigateToItem}
            handleChevron={handleChevron}
            playlistTitle={playlistTitle}
            currFeedCardIndex={currFeedCardIndex} // Index of the current visible FeedCard
            index={index + 1} // Index of this FeedCard, isVisible = (currFeedCardIndex === index)
            navigateToNextItem={navigateToNextItem}
          />
        </div>
      ))}
      <div
        className={`feed-navigation ${isDetailMode && deviceType !== "mobile" && (type !== "playlist" || ![1, data.length].includes(currFeedCardIndex)) ? `focus-mode ${deviceType}` : ""}`}
        onMouseEnter={() => setIsUpChevronHovered(true)}
        onMouseLeave={() => setIsUpChevronHovered(false)}
      >
        {isUpChevronHovered && deviceType !== "mobile" && (
          <div
            className={`chevron-container ${isUpChevronHovered ? "show-double-up" : ""}`}
          >
            {currFeedCardIndex != 1 && deviceType !== "mobile" ? (
              <button
                id="chevron-up-up"
                onClick={() => {
                  handleChevron(1);
                }}
              >
                <img src={chevronUpUp} alt="Scroll Up" />
              </button>
            ) : null}
          </div>
        )}
        <div className={`chevron-container`}>
          {currFeedCardIndex != 1 && deviceType !== "mobile" ? (
            <button
              id="chevron-up"
              onClick={() => handleChevron(currFeedCardIndex - 1)}
            >
              <img src={chevronUp} alt="Scroll Up" />
            </button>
          ) : null}
        </div>
        <div className="chevron-container">
          {currFeedCardIndex !== totalNumOfCards && deviceType !== "mobile" ? (
            <button
              id="chevron-down"
              onClick={() => handleChevron(currFeedCardIndex + 1)}
            >
              <img src={chevronDown} alt="Scroll down" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FeedContainer;
